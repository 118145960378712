import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer-container.css'

const FooterContainer = (props) => {
  return (
    <div
      className={`footer-container-footer-container ${props.rootClassName} `}
    >
      
      <div className="home-section-separator3"></div>
      <div className="footer-container-footer">  
        <div className="footer-container-container">
          <Link to="/aviso-legal" className="footer-container-navlink">
            {props.text}
          </Link>
          <Link to="/poltica-de-privacidad" className="footer-container-link">
            {props.text3}
          </Link>
        </div>
        <div className="footer-container-copyright-container">
          <svg viewBox="0 0 1024 1024" className="footer-container-icon">
            <path
              d="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM506 390q-80 0-80 116v12q0 116 80 116 30 0 50-17t20-43h76q0 50-44 88-42 36-102 36-80 0-122-48t-42-132v-12q0-82 40-128 48-54 124-54 66 0 104 38 42 42 42 98h-76q0-14-6-26-10-20-14-24-20-20-50-20z"
              className=""
            ></path>
          </svg>
          <span className="Anchor">{props.link}</span>
        </div>
      </div>
    </div>
  )
}

FooterContainer.defaultProps = {
  rootClassName: '',
  text: 'Aviso Legal',
  link: 'Copyright, 2024',
  linkText: 'https://example.com',
  text1: 'Link',
  linkText1: 'https://example.com',
  text2: 'Política de Privacidad',
  linkText2: 'https://example.com',
  text3: 'Política de Privacidad',
  linkText3: 'https://example.com',
  linkText4: 'https://example.com',
  linkText5: 'https://example.com',
}

FooterContainer.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  text1: PropTypes.string,
  linkText1: PropTypes.string,
  text2: PropTypes.string,
  linkText2: PropTypes.string,
  text3: PropTypes.string,
  linkText3: PropTypes.string,
  text4: PropTypes.string,
  linkText4: PropTypes.string,
  text5: PropTypes.string,
  linkText5: PropTypes.string,
}

export default FooterContainer
