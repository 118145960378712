import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './aviso-legal.css'
import SiteContainer from '../components/site-container'

const AvisoLegal = (props) => {
  return (
    <SiteContainer>
    <div className="aviso-legal-container">
            <div className="aviso-legal-banner">
        <h1 className="aviso-legal-text">Aviso Legal</h1>
      </div>
      <div className="aviso-legal-container1">
        <span className="aviso-legal-text001">
          <span>AVISO LEGAL</span>
          <br></br>
          <span>
            En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio,
            de Servicios de la Sociedad de la Información y Comercio
            Electrónico, a continuación se exponen nuestros datos:
          </span>
          <br></br>
          <span>Convergencia de Telecomunicaciones S.L</span>
          <br></br>
          <span>C/ Marcelino González Nº 4 Bajo</span>
          <br></br>
          <span>C.P. 33209 Gijón (Asturias)</span>
          <br></br>
          <span>B33902156</span>
          <br></br>
          <span>Tfno: 985 99 06 76 -- – e-mail: web@contelnet.com</span>
          <br></br>
          <span>
            Registro Mercantil de Asturias, Tomo 3133, Libro:0, Folio:35 ,Hoja:
            AS-29136, Inscripción: 1
          </span>
          <br></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <span>OBJETO</span>
          <br></br>
          <span>
            Convergencia de Telecomunicaciones S.L (en adelante, La empresa) es
            la titular y responsable del sitio web; y pone a disposición de los
            usuarios el presente documento con el que pretende dar cumplimiento
            a las obligaciones dispuestas en la Ley 34/2002, de Servicios de la
            Sociedad de la Información y del Comercio Electrónico (LSSI-CE), así
            como informar a todos los usuarios del sitio web respecto a cuáles
            son las condiciones de uso del sitio web.
          </span>
          <br></br>
          <br></br>
          <span>
            Toda persona que acceda a este sitio web asume el papel de usuario,
            comprometiéndose a la observancia y cumplimiento riguroso de las
            disposiciones aquí dispuestas, así como a cualquier otra disposición
            legal que fuera de aplicación.
          </span>
          <br></br>
          <br></br>
          <span>
            La empresa se reserva el derecho a mejorar, modificar o suprimir
            cualquier tipo de información o contenido que pudiera aparecer en el
            sitio web, sin que exista obligación de preaviso, entendiéndose como
            suficiente con la publicación en el sitio web del prestador.
          </span>
          <br></br>
          <br></br>
          <span>ACEPTACIÓN DE LOS TÉRMINOS DE USO</span>
          <br></br>
          <span>
            La utilización del presente sitio web, es absolutamente voluntaria y
            supone la aceptación plena por quien accede al mismo- en adelante el
            usuario-, de todos los términos de uso vigentes en cada momento que
            se encuentren en la presente dirección; por lo cual el usuario
            deberá leer detenidamente y aceptar sin ningún tipo de reservas la
            presente información antes de realizar cualquier tipo de operación,
            visionado, utilización, etc., en el presente sitio web.
          </span>
          <br></br>
          <span>
            El usuario se compromete a no utilizar los contenidos del presente
            sitio web, o sus servicios, de forma contraria a lo dispuesto por la
            legislación vigente.
          </span>
          <br></br>
          <span>
            Convergencia de Telecomunicaciones S.L se reserva el derecho a
            retirar el acceso a la presente página web, sin necesidad de previo,
            a cualquier usuario que contravenga lo dispuesto en el presente
            documento de “Aviso legal y Términos de Uso”.
          </span>
          <br></br>
          <span>
            Convergencia de Telecomunicaciones S.L se reserva el derecho de
            modificar en cualquier momento el presente documento de “Aviso Legal
            y Términos de Uso”. Así mismo se reserva el derecho a modificar en
            cualquier momento la presentación, configuración y localización del
            sitio Web, así como los contenidos y las condiciones requeridas para
            utilizar los mismos.
          </span>
          <br></br>
          <br></br>
          <span>TUS DERECHOS Y TUS DEBERES</span>
          <br></br>
          <br></br>
          <span>A través del presente sitio web podrás:</span>
          <br></br>
          <br></br>
          <span>
            · Acceder de forma gratuita y sin necesidad de autorización previa a
            los contenidos y servicios del sitio web de Convergencia de
            Telecomunicaciones S.L, disponible como tal, sin perjuicio de las
            condiciones técnicas, particulares o la necesidad del previo
            registro según se determine en cada caso.
          </span>
          <br></br>
          <br></br>
          <span>
            · Utilizar el sitio web de Convergencia de Telecomunicaciones S.L,
            sus servicios y contenidos de forma exclusivamente particular.
          </span>
          <br></br>
          <br></br>
          <span>
            · Hacer un uso correcto y lícito del sitio web de Convergencia de
            Telecomunicaciones S.L, de conformidad con estas condiciones, la
            legislación vigente, la moral, las buenas costumbres y el orden
            público.
          </span>
          <br></br>
          <br></br>
          <span>En ningún caso puedes:</span>
          <br></br>
          <br></br>
          <span>
            · Acceder o utilizar el sitio web para la comisión de acciones
            ilícitas o contrarias a la legislación vigente, la moral, las buenas
            costumbres y el orden público, con fines lesivos de derechos y
            libertades de terceros, o que puedan perjudicar, dañar o impedir por
            cualquier forma, el acceso a los mismos, en perjuicio de
            Convergencia de Telecomunicaciones S.L o de terceros.
          </span>
          <br></br>
          <br></br>
          <span>
            · Introducir información en el sitio web o utilizarlos de tal modo
            que inciten o promuevan la realización de actos delictivos,
            xenófobos, terroristas o degradantes por razón de edad, sexo,
            religión o creencias; o de carácter pornográfico, obsceno, violento
            o que atenten contra la ley, la moral o las buenas costumbres. A
            estos efectos, por información se entenderá, con carácter
            delimitativo pero no limitativo: textos, gráficos, imágenes, vídeos,
            sonidos, dibujos, fotografías, datos, notas, y otros.
          </span>
          <br></br>
          <br></br>
          <span>
            · Utilizar el sitio web y móviles, total o parcialmente, para
            promocionar, vender, contratar, divulgar publicidad o información
            propia o de terceras personas sin autorización previa y por escrito
            de Convergencia de Telecomunicaciones S.L.
          </span>
          <br></br>
          <br></br>
          <span>
            · Realizar cualquier acción que impida o dificulte el acceso al
            sitio web y móviles por los usuarios, así como de los hipervínculos
            a los servicios y contenidos ofrecidos por Convergencia de
            Telecomunicaciones S.L o por otros terceros autorizados.
          </span>
          <br></br>
          <br></br>
          <span>
            · Emplear cualquier tipo de virus informático, código, software,
            programa informático, equipo informático o de telecomunicaciones,
            que puedan provocar daños o alteraciones no autorizadas de los
            contenidos, programas o sistemas accesibles a través del sitio web o
            en los sistemas de información, archivos y equipos informáticos de
            otros.
          </span>
          <br></br>
          <br></br>
          <span>
            · Eliminar o modificar de cualquier modo los dispositivos de
            protección o identificación de Convergencia de Telecomunicaciones
            S.L o sus legítimos titulares que puedan contener los contenidos
            alojados en el sitio web, o los símbolos que Convergencia de
            Telecomunicaciones S.L o los terceros legítimos titulares de los
            derechos incorporen a sus creaciones objeto de propiedad intelectual
            o industrial existentes en este sitio web.
          </span>
          <br></br>
          <br></br>
          <span>
            · Reproducir total o parcialmente nuestro sitio web en otro sitio
            web o móvil distinto; realizar enmarcados que oculten o modifiquen
            con carácter delimitativo pero no limitativo- contenidos, espacios
            publicitarios y marcas de Convergencia de Telecomunicaciones S.L o
            de terceros, con independencia o no de que supongan actos de
            competencia desleal o de confusión; o crear marcos que reproduzcan
            la página principal y/o las páginas accesibles a través de la misma,
            correspondientes a nuestro sitio web y móviles sin la previa
            autorización de Convergencia de Telecomunicaciones S.L.
          </span>
          <br></br>
          <br></br>
          <span>
            · Realizar cualquier acción que suponga la reproducción,
            distribución, copia, alquiler, comunicación pública, transformación
            o cualquier otra acción similar que suponga la modificación o
            alteración, de todo o parte de los contenidos y servicios del sitio
            web (incluida la marca, nombres comerciales, así como cualquier otro
            signo identificativo) o la explotación económica de los mismos, sin
            la autorización previa y por escrito de Convergencia de
            Telecomunicaciones S.L o del tercero propietario de los derechos de
            propiedad intelectual e industrial que recaigan sobre los servicios
            o contenidos del sitio web.
          </span>
          <br></br>
          <br></br>
          <span>RESPONSABILIDAD</span>
          <br></br>
          <br></br>
          <span>
            Este sitio Web y sus contenidos han sido elaborados para la
            presencia y promoción de la empresa en internet y demás redes
            sociales. Así puedes disfrutar del uso de diversos contenidos y
            servicios que serán ofrecidos por Convergencia de Telecomunicaciones
            S.L, o por otros terceros proveedores y/o colaboradores en las
            condiciones que se determinen en cada caso.
          </span>
          <br></br>
          <br></br>
          <span>
            El prestador se exime de cualquier tipo de responsabilidad derivada
            de la información publicada en su sitio web, siempre que esta
            información haya sido manipulada o introducida por un tercero ajeno
            al mismo.
          </span>
          <br></br>
          <br></br>
          <span>
            El titular del sitio web no se responsabiliza del daño o perjuicio
            que puedan ocasionar dichos contenidos o su veracidad a terceras
            personas, ni de los contenidos a los que se acceda de forma
            indirecta través de enlaces de otros sitios Web, publicados en el
            presente sitio Web.
          </span>
          <br></br>
          <br></br>
          <span>
            El titular del sitio web no será responsable de los daños o
            perjuicios que pudieran derivarse de la utilización de los
            contenidos por parte de los usuarios, o de la infracción por parte
            de éstos de cualquier disposición legal vigente.
          </span>
          <br></br>
          <br></br>
          <span>PROPIEDAD INTELECTUAL E INDUSTRIAL</span>
          <br></br>
          <br></br>
          <span>
            El presente sitio web y todos sus contenidos, incluyendo a título
            enunciativo pero no limitativo, su programación, edición, diseño,
            compilación, diseños, logotipos, texto y/o gráficos, así como todas
            las marcas, nombres comerciales o cualquier otro signo distintivo ,
            son propiedad del titular y responsable del sitio web, encontrándose
            protegidos por la normativa nacional e internacional sobre propiedad
            intelectual e industrial.
          </span>
          <br></br>
          <br></br>
          <span>
            El acceso por parte del usuario al sitio web no le otorga ningún
            derecho de propiedad sobre los mismos. Por tanto dicho uso no
            implica en ningún caso la cesión, renuncia o transmisión, total o
            parcial por Convergencia de Telecomunicaciones S.L de la titularidad
            de los correspondientes derechos de propiedad intelectual e
            industrial. De hecho, bajo ningún concepto, podrás realizar un uso o
            utilización de nuestro sitio web, así como de sus contenidos y
            servicios que no sea exclusivamente personal. Con excepción de los
            actos de reproducción provisional, transitorios o accesorios que
            formen parte integrante del proceso tecnológico para facilitarte el
            acceso y disfrute de nuestro sitio, ninguna parte de éste puede ser
            reproducida, distribuida, transmitida, copiada, comunicada
            públicamente ni transformada, en todo o en parte mediante ningún
            sistema o método manual, electrónico o mecánico (incluyendo el 
          </span>
          <span>
            fotocopiado, grabación o cualquier sistema de recuperación y
            almacenamiento de información) a través de cualquier soporte
            actualmente conocido o que se invente en el futuro, sin autorización
            de Convergencia de Telecomunicaciones S.L.
          </span>
          <br></br>
          <br></br>
          <span>
            Cualquier uso no autorizado previamente por parte del prestador será
            considerado un incumplimiento grave de los derechos de propiedad
            intelectual o industrial del autor.
          </span>
          <br></br>
          <br></br>
          <span>
            Convergencia de Telecomunicaciones S.L autoriza el establecimiento
            de enlaces entre otras webs y la suya, siempre que se respeten las
            siguientes condiciones:
          </span>
          <br></br>
          <br></br>
          <span>
            1. Que el enlace no se establezca desde una web cuyos contenidos
            resulten contrarios a la Ley, a la moral y al orden público.
          </span>
          <br></br>
          <br></br>
          <span>
            2. Que no se ofrezca una imagen de Convergencia de
            Telecomunicaciones S.L. o de sus productos que resulte
            distorsionada, perjudicial, o equivocada.
          </span>
          <br></br>
          <br></br>
          <span>
            3. Que no se cree la impresión de que concurre una relación o
            vinculación comercial entre Convergencia de Telecomunicaciones S.L y
            los titulares, responsables o anunciantes de otros sitios web, en
            donde se alojen enlaces al presente sitio Web o a sus contenidos.
          </span>
          <br></br>
          <br></br>
          <span>
            Si ve en el sitio web cualquier contenido que pudiera vulnerar
            derechos de propiedad intelectual e industrial, rogamos lo pongan en
            conocimiento del titular del sitio web con la mayor brevedad
            posible, remitiendo un correo electrónico a la dirección:
            web@contelnet.com
          </span>
          <br></br>
          <br></br>
          <span>LEY APLICABLE Y JURISDICCIÓN</span>
          <br></br>
          <br></br>
          <span>
            Para la resolución de todas las controversias o cuestiones
            relacionadas con el presente sitio web o de las actividades en él
            desarrolladas, será de aplicación la legislación española; En estos
            casos, el usuario, con renuncia expresa a cualquier otro fuero que
            pudiera corresponderle, se someten expresamente al fuero de los
            Juzgados y Tribunales de Oviedo.
          </span>
          <br></br>
          <br></br>
          <span>Convergencia de Telecomunicaciones S.L</span>
          <br></br>
          <span>C/ Marcelino González Nº 4 Bajo</span>
          <br></br>
          <span>C.P. 33209 Gijón (Asturias)</span>
          <br></br>
          <span>B33902156</span>
          <br></br>
          <span>Tfno: 985 99 06 76 -- – e-mail: web@contelnet.com</span>
          <br></br>
          <span>
            Registro Mercantil de Asturias, Tomo 3133, Libro:0, Folio:35 ,Hoja:
            AS-29136, Inscripción: 1
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br/>
          Esta página usa Material Design Icons de Google, bajo la
          licencia <a href="https://www.apache.org/licenses/LICENSE-2.0.txt">Apache License Version 2.0</a>
        </span>
      </div>
      
    </div>
    </SiteContainer>
  )
}

export default AvisoLegal
