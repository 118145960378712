import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Ciberseguridad from './views/ciberseguridad'
import KitDigital from './views/kit-digital'
import AvisoLegal from './views/aviso-legal'
import Home from './views/home'
import PolticaDePrivacidad from './views/poltica-de-privacidad'
import InformacinDeContacto from './views/informacin-de-contacto'
import NotFound from './views/not-found'
import comunicacionesSeguras from './views/comunicaciones-seguras'  
import oficinaVirtual from './views/oficina-virtual'
import Nosotros from './views/nosotros'
import ServiciosCloud from './views/servicios-cloud'
import Infraestructura from './views/infraestructura'
import CentralitaCloud from './views/centralita-cloud'
import Puestots from './views/puestots'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Ciberseguridad} exact path="/ciberseguridad" />
        <Route component={comunicacionesSeguras} exact path="/comunicaciones-seguras" />
        <Route component={oficinaVirtual} exact path="/oficina-virtual" />
        <Route component={KitDigital} exact path="/kit-digital" />
        <Route component={AvisoLegal} exact path="/aviso-legal" />
        <Route component={Home} exact path="/"/>
        <Route component={ServiciosCloud} exact path="/servicios-cloud"/>
        <Route component={Nosotros} exact path="/nosotros"/>
        <Route component={Infraestructura} exact path="/infraestructura"/>
        <Route component={CentralitaCloud} exact path="/centralita-cloud"/>
        <Route component={Puestots} exact path ="/puesto-de-trabajo-seguro"/>
        <Route
          component={PolticaDePrivacidad}
          exact
          path="/poltica-de-privacidad"
        />
        <Route
          component={InformacinDeContacto}
          exact
          path="/informacin-de-contacto"
        />
        
        <Route exact path="/epages/ec5323.sf/es_ES" render={({ location }) => {
          const queryParams = new URLSearchParams(location.search);
          const objectPath = queryParams.get('ObjectPath');
          
          if (objectPath === "/Shops/ec5323/Categories/Kit_Digitalizador/Ciberseguridad") {
            return <Redirect to="/ciberseguridad" />;
          } else if (objectPath === "/Shops/ec5323/Categories/Kit_Digitalizador/Comunicaciones_Seguras") {
            return <Redirect to="/comunicaciones-seguras" />;
          } else if (objectPath === "/Shops/ec5323/Categories/Kit_Digitalizador/Servicios_y_herramientas_de_oficina_Virtual") {
            return <Redirect to="/oficina-virtual" />;
          } else {
            return <Redirect to="/" />; // Default redirect
          }
        }} />

        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
