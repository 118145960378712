import React from 'react'

import InfoContainer from '../components/info-container';
import SiteContainer from '../components/site-container';

import './servicios-cloud.css'

const ServiciosCloud = (props) => {
    return (
        <SiteContainer>
            <InfoContainer>
                <h1>Servicios</h1>
                <h2>
                    Servicios basados en la nube que se adaptan a las necesidades
                    de la empresa
                </h2>
                <div className="services-box">
                    <div className="service servicios-nube">
                        <h1>Soluciones en la nube</h1>
                    </div>
                    <div className="service servicios-ciberseguridad">
                        <h1>Ciberseguridad</h1>
                    </div>
                    <div className="service servicios-voip">
                        <h1>
                        Telefonía VoIP y FTTH
                        </h1>
                    </div>
                    <div className="service servicios-iot">
                        <h1>IoT y M2M</h1>
                    </div>
                </div>
                <h1 id="infrah1">Infraestructura</h1>
                <h2>Soluciones a todas las necesidades tecnológicas para la consolidación y evolución de tu empresa</h2>
                <img src="/files/infraestructura.png" alt="Descripción de nuestros servicios de infraestructura" className="timeline1-image2"></img>
                <div className="contact-cta-btn-container-cloud">
                    <a href="/informacin-de-contacto" className="contact-cta-btn">Solicítanos información</a>
                </div>
            </InfoContainer>
        </SiteContainer>
    )
}

export default ServiciosCloud