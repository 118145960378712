import React from 'react'

import InfoContainer from '../components/info-container';
import SiteContainer from '../components/site-container';

import './centralita-cloud.css'

const CentralitaCloud = (props) => {
    return (
        <SiteContainer>
            <InfoContainer>
                <h1>Centralita Cloud</h1>
                <p className="centralita-cloud-title">
                    Una Centralita Cloud se trata de un sistema telefónico que utiliza una conexión a Internet en lugar de una línea telefónica tradicional.
                </p>
                <p className="centralita-cloud-title">
                    Este tipo de sistema tiene un coste menor al tradicional mientras que ofrece comunicaciones de mayor calidad.
                </p>
                <div className="centralita-benefits-container">
                    <div>
                        <h1>Rápida instalación</h1>
                        
                    </div>
                    <div>
                        a
                    </div>
                    <div>
                        a
                    </div>
                    <div>
                        a
                    </div>
                    <div>
                        a
                    </div>
                    <div>
                        a
                    </div>
                </div>
            </InfoContainer>
        </SiteContainer>
    )
}

export default CentralitaCloud