import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './comunicaciones-seguras.css'

const comunicacionesSeguras = (props) => {
  return (
    <div className="comunicaciones-seguras-container">
      <Helmet>
        <title>ConTel - Convergencia de Telecomunicaciones</title>
        <meta property="og:title" content="ConTel - Convergencia de Telecomunicaciones" />
      </Helmet>
      <NavbarContainer rootClassName="navbar-container-root-class-name2"></NavbarContainer>
      <div className="comunicaciones-seguras-banner">
        <h1 className="comunicaciones-seguras-text">Kit Digital: Comunicaciones Seguras</h1>
      </div>
      <div className="comunicaciones-seguras-container1">
        <div className="comunicaciones-seguras-container2">
          <h1>Comunicaciones seguras</h1>
          Con esta solución dispondrás de seguridad en las conexiones entre los dispositivos de tus empleados y la empresa.
          

<h2>Funcionalidades y servicios</h2>

<ul>
	<li className="list-item">
	<strong>SSL:</strong> dispondrás de un protocolo de capa de sockets seguros, para crear una conexión segura y cifrada.
	</li>
	<li className="list-item">
	<p><strong>Cifrado de extremo a extremo:</strong> tendrás las comunicaciones cifradas en todo su recorrido, con el objetivo de prevenir ataques.</p>
	</li>
	<li className="list-item">
	<p><strong>Logs de conexión:</strong> serás capaz de mantener un registro de los dispositivos que se han conectado a tu red privada.</p>
	</li>
	<li className="list-item">
	<p><strong>Control de acceso: </strong>tendrás la posibilidad de permitir la conexión a tu red privada única y exclusivamente a los dispositivos autorizados por ti.</p>
	</li>
	<li className="list-item">
	<p><strong>Dispositivos móviles: </strong>podrás hacer uso de esta solución desde dispositivos móviles.</p>
	</li>
	<li className="list-item">
	<p><strong>Configuración inicial y actualizaciones de seguridad: </strong>dispondrás de una configuración inicial para que puedas usarlo correctamente, con las respectivas actualizaciones de firmas de malware y otros datos para detección de amenazas además de las actualizaciones de software de seguridad periódicas requeridas.</p>
	</li>
</ul>

<h2 >Porcentajes de ejecución asociados a las fases</h2>

<ul>
	<li className="list-item">
	<p>Primera: 70 %</p>
	</li>
	<li >
	<p>Segunda: 30 %</p>
	</li>
</ul>

<h2 >Ayuda máxima y precio desde:</h2>

<ul>
	<li className="list-item">
	<p>0 &lt; 3 empleados: 125€/usuario (hasta 2 usuario)</p>
	</li>
	<li className="list-item">
	<p>3 &lt; 9 empleados: 125€/usuario (hasta 9 usuario)</p>
	</li>
	<li className="list-item">
	<p>10 &lt; 50 empleados: 125€/usuario (hasta 48 usuario)</p>
	</li>
</ul>

<p><h2>Solución propuesta</h2></p>

<p>Sistema de VPN + Firewall que cumple estos requisitos:</p>

<ul>
	<li className="list-item">
	<strong>SSL:</strong> dispondrás de un protocolo de capa de sockets seguros, para crear una conexión segura y cifrada.
	</li>
	<li className="list-item">
	<p><strong>Cifrado de extremo a extremo:</strong> tendrás las comunicaciones cifradas en todo su recorrido, con el objetivo de prevenir ataques.</p>
	</li>
	<li className="list-item">
	<p><strong>Logs de conexión:</strong> serás capaz de mantener un registro de los dispositivos que se han conectado a tu red privada.</p>
	</li>
	<li className="list-item">
	<p><strong>Control de acceso: </strong>tendrás la posibilidad de permitir la conexión a tu red privada única y exclusivamente a los dispositivos autorizados por ti.</p>
	</li>
	<li className="list-item">
	<p><strong>Dispositivos móviles: </strong>podrás hacer uso de esta solución desde dispositivos móviles.</p>
	</li>
	<li className="list-item">
	<p><strong>Configuración inicial y actualizaciones de seguridad: </strong>dispondrás de una configuración inicial para que puedas usarlo correctamente, con las respectivas actualizaciones de firmas de malware y otros datos para detección de amenazas además de las actualizaciones de software de seguridad periódicas requeridas.</p>
	</li>
</ul>
<div className="contact-cta-btn-container">
        <a href="/informacin-de-contacto" className="contact-cta-btn">Solicítanos información</a>
      </div>
        </div>
		
        <img src="files/kitdigital.png" className="kitdigital-footer" alt="Logo Kit Digital"></img>
      </div>
      <FooterContainer rootClassName="footer-container-root-class-name2"></FooterContainer>
    </div>
  )
}

export default comunicacionesSeguras
