import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './ciberseguridad.css'

const Ciberseguridad = (props) => {
  return (
    <div className="ciberseguridad-container">
      <Helmet>
        <title>ConTel - Convergencia de Telecomunicaciones</title>
        <meta property="og:title" content="Ciberseguridad - ConTel - Convergencia de Telecomunicaciones" />
      </Helmet>
      <NavbarContainer rootClassName="navbar-container-root-class-name2"></NavbarContainer>
      <div className="ciberseguridad-banner">
        <h1 className="ciberseguridad-text">Kit Digital: Ciberseguridad</h1>
      </div>
      <div className="ciberseguridad-container1">
        <div className="ciberseguridad-container2">
          <ul className="ciberseguridad-ul list">
            <li className="list-item">
              <span>
                <span>
                  <b>Antimalware:</b> tendrás a tu disposición una herramienta que
                  analice tu dispositivo, su memoria interna y los dispositivos
                  de almacenamiento externos.
                </span>
                <br></br>
              </span>
            </li>
            <li className="list-item">
              <span>
                <b>Antispyware:</b> dispondrás de una herramienta que detecte y evite
                el malware espía.
              </span>
            </li>
            <li className="list-item">
              <span>
                <b>Correo seguro:</b> tendrás herramientas de análisis del correo
                electrónico con las siguientes características:
              </span>
              <ul className="ciberseguridad-ul1 list">
                <li className="list-item">
                  <span>
                    <b>Antispam,</b> con detección y filtro de correo no deseado.
                  </span>
                </li>
                <li className="list-item">
                  <span>
                    <b>Antiphishing,</b> con detección de correos con enlaces o malware
                    que se sospecha sirvan para robar credenciales.
                  </span>
                </li>
              </ul>
            </li>
            <li className="list-item">
              <span><b>Navegación segura:</b> tendrás asegurado:</span>
              <ul className="ciberseguridad-ul2 list">
                <li className="list-item">
                  <span>Control de contenidos</span>
                </li>
                <li className="list-item">
                  <span>Antiadware para evitar anuncios maliciosos</span>
                </li>
              </ul>
            </li>
            <li className="list-item">
                  <span><b>Análisis y detección de amenazas</b>serás capaz de conocer el comportamiento de las amenazas conocidas y nuevas.</span>
            </li>
            <li className="list-item">
        <span><b>Monitorización de la red:</b> tendrás herramientas que analizan el tráfico de red y te alerten de amenazas.</span>
      </li>
      <li className="list-item">
        <span><b>Configuración inicial y actualizaciones de seguridad:</b> dispondrás de una configuración inicial para su correcto uso, con las respectivas actualizaciones de firmas de malware y otros datos para detección de amenazas además de las actualizaciones de software de seguridad periódicas requeridas.</span>
      </li>
      <li className="list-item">
        <span><b>Requisitos especiales de formación:</b> dispondrás de formación para la configuración del software de seguridad, y tendrás un kit de concienciación en ciberseguridad para complementar la solución con habilidades de firewall humano.</span>
      </li>
          </ul>
          <h2 className="ciberseguridad-h2">Porcentajes de ejecución asociados a las fases</h2>
          <ul className="ciberseguridad-ul list">
            <li className="list-item">Primera: 70 %</li>
            <li className="list-item">Segunda: 30 %</li>
          </ul>
          <h2 className="ciberseguridad-h2">Importe máximo de la ayuda</h2>
          <ul className="ciberseguridad-ul list">
            <li className="list-item">0 &lt; 3 empleados: 125€/dispositivo (hasta 2 dispositivos)</li>
            <li className="list-item">3 &lt; 9 empleados: 125€/dispositivo (hasta 9 dispositivos)</li>
            <li className="list-item">10 &lt; 50 empleados: 125€/dispositivo (hasta 48 dispositivos)</li>
          </ul>
          <h2 className="ciberseguridad-h2">Descripción de la solución propuesta</h2>
          <p className="ciberseguridad-paragraph">
              <u><strong><em>Bitdefender Cloud Security</em></strong></u>
          </p>
          <p className="ciberseguridad-paragraph">
            <ul className="ciberseguridad-ul list">
              <li className="list-item"><strong>Protección antimalware y antivirus completa</strong>: Bitdefender
                proporciona una combinación avanzada de Antivirus/
                Antimalware para que sólo necesite una solución de
                seguridad, mitigando las brechas de seguridad, simplificando
                las implementaciones y reduciendo los gastos. Con más de
                diez años de experiencia y siete patentes, Bitdefender ha
                perfeccionado los algoritmos de aprendizaje automático para
                bloquear las nuevas amenazas evasivas con un mínimo de
                falsos positivos.
              </li>
              <li className="list-item"><strong>Antiexploit avanzado</strong>: El antiexploit avanzado de Bitdefender
              no solo brinda protección contra los exploits conocidos que
              intentan secuestrar los programas más populares, sino también
              Bitdefender es una compañía global de tecnología de seguridad que proporciona soluciones de seguridad cibernética de vanguardia y protección avanzada contra amenazas a más de 500
              millones de usuarios en más de 150 países. Desde 2001, Bitdefender ha producido constantemente tecnología galardonada de seguridad para empresas y consumidores, y es un proveedor de elección tanto en seguridad de infraestructura híbrida como en protección de puntos finales. A través de I+D, alianzas y asociaciones, se confía en que Bitdefender está a la vanguardia y ofrece
              una seguridad sólida en la que puede confiar.
            </li>
            <li className="list-item"><strong>Monitorización continua de procesos</strong>: El Inspector de procesos de Bitdefender monitoriza continuamente los procesos en ejecución en busca de signos de comportamiento malicioso y puede
              detectar y bloquear malware y ataques que eludieran otras capas
              de seguridad.
            </li>
            <li className="list-item"><strong>Control de contenido</strong>: Bloqueo de URL por categoría, por
              programación o utilizando una tabla de exclusiones configurable
              de bloqueo/permiso.
            </li>
            <li className="list-item"><strong>Control de dispositivos</strong>: Las amenazas suelen introducirse en
              la empresa a través de dispositivos extraíbles. El control de
              dispositivos de Bitdefender le permite elegir qué dispositivos y
              tipos de dispositivos podrán ejecutarse y cuáles serán bloqueados
              o analizados automáticamente.
            </li>
            <li className="list-item"><strong>Cortafuego con IDS</strong>: Los endpoints están protegidos con un
              completo cortafuego bidireccional con detección de intrusos,
              crucial para proteger dispositivos de forma remota.
            </li>
            <li className="list-item"><strong>Filtrado web y asesor de búsquedas:</strong> Bitdefender muestra
              clasificaciones de búsquedas que identifican las páginas seguras
              y las que no lo son. Bloquea sitios web de phishing o maliciosos y
              analiza el tráfico HTTP(S) para detectar amenazas.
            </li>
            </ul>
          </p>
          <p className="ciberseguridad-paragraph">
		  Servicios opcionales que se pueden administrar desde la misma consola MSP y usan el mismo agente pero que se facturan por separado
			<ul className="ciberseguridad-ul list">
            
            <li className="list-item"><strong>Security for Virtualized Environments</strong>: En lugar de poner una
              copia de Bitdefender en cada máquina virtual, SVE utiliza una VM
              que convierte en appliance virtual de seguridad (SVA) y se encargan de proteger todas las demás máquinas virtuales en el entorno
              del host. Al ejecutarse una sola instancia del agente antimalware
              de Bitdefender en el entorno virtualizado en lugar de muchas,
              el rendimiento, las tasas de consolidación y la experiencia del
              usuario mejoran extraordinariamente.
            </li>
            <li className="list-item"><strong>Cifrado de disco completo</strong>: La consola de Bitdefender es
              ahora el centro neurálgico, no solo para proteger los endpoints
              de sus clientes contra el software malicioso y los ataques
              personalizados, sino también para los informes de cumplimiento
              normativo y la recuperación de claves de cifrado. Evite que los
              datos confidenciales se vean comprometidos cuando se produce
              el robo de un portátil y ayude a los clientes a cumplir con HIPAA,
              RGPD y otras normativas mediante el nuevo servicio opcional
              ahora disponible con facturación mensual para los MSP. El
              Cifrado de disco completo de Bitdefender aprovecha los probados
              mecanismos nativos proporcionados por Windows (BitLocker) y
              Mac (FileVault) para garantizar la compatibilidad y el rendimiento.
              Es fácil administrar o restaurar claves desde la consola, así como
              generar informes de cifrado.
            </li>
            <li className="list-item"><strong>Security for Exchange</strong>: Consolide en una sola consola con
              Bitdefender la seguridad de endpoints y de los correos electrónicos de MS Exchange, así como el antispam. Este servicio opcional facturable disponible incluye antimalware de correo electrónico con detección de comportamiento, antiphishing, filtrado de contenidos y adjuntos y antispam, que le ayudarán a mantener a los clientes productivos y a salvo de las amenazas de correo electrónico.
            </li>
            <li className="list-item"><strong>Administración de parches</strong>: La Administración de parches
              de Bitdefender reduce los esfuerzos y costes de la aplicación
              de parches y mitiga más rápidamente las vulnerabilidades de
              las aplicaciones al ofrecer una única fuente para estos con la
              mayor base de datos disponible para Windows y aplicaciones de
              terceros.
            </li>
            <li className="list-item"><strong>Advanced Threat Security</strong>: Bitdefender Advanced Threat Security incluye aprendizaje automático optimizable y espacio aislado en la nube, lo que permite a los MSP o MSSP ofrecer a sus
              clientes servicios de búsqueda de amenazas muy sensibles a
              las vulneraciones de datos. Estas capas de seguridad avanzada
              brindan protección contra ataques sin archivos o basados en
              scripts, malware personalizado, ataques selectivos, aplicaciones
              potencialmente no deseadas y ransomware avanzado, además de
              ofrecer visibilidad y contexto de las amenazas.
            </li>
            <li className="list-item"><strong>EDR (detección y respuesta en los endpoints)</strong>: La EDR
              monitoriza constantemente las actividades de los endpoints y
              busca comportamientos anómalos para ofrecer una visibilidad
              anticipada de los ataques avanzados, indicadores de compromiso
              (IoC), investigación de amenazas con un solo clic y flujos
              de trabajo de respuesta ante incidentes. Esto permite a los
              proveedores de servicios satisfacer las demandas de los clientes y hacer crecer su negocio con servicios administrados de detección y respuesta.
            </li>
			</ul>
          </p>
          <div className="contact-cta-btn-container">
        <a href="/informacin-de-contacto" className="contact-cta-btn">Solicítanos información</a>
      </div>
        </div>
        <img src="files/kitdigital.png" className="kitdigital-footer" alt="Logo Kit Digital"></img>
      </div>
      <FooterContainer rootClassName="footer-container-root-class-name2"></FooterContainer>
    </div>
  )
}

export default Ciberseguridad
