import React from 'react'

import InfoContainer from '../components/info-container';
import SiteContainer from '../components/site-container';

import './infraestructura.css'

const Infraestructura = (props) => {
    return (
        <SiteContainer>
            <InfoContainer>
                <h1>Infraestructura</h1>
                <span className="subtitle">
                    Soluciones a todas las necesidades tecnológicas 
                    para la consolidación y evolución 
                </span>
                <div className="steps">
                    <div className="infraestructura-features-card">
                <div className="infraestructura-icon-container">
                <svg viewBox="0 0 1024 1024" className="infraestructura-icon">
                    <path d="M533.333 234.667c-11.776 0-21.333 9.557-21.333 21.333s9.557 21.333 21.333 21.333c46.208 0 83.797 37.589 83.797 83.797 0 11.776 9.557 21.333 21.333 21.333s21.333-9.557 21.333-21.333c0-69.717-56.747-126.464-126.464-126.464z"></path>
                    <path d="M533.333 42.667c-176.469 0-320 143.531-320 320 0 63.403 18.773 124.672 54.357 177.707 0.981 2.005 2.091 3.925 3.413 5.76 77.867 111.189 91.563 146.347 91.563 179.2v128c0 23.552 19.115 42.667 42.667 42.667h85.333c0 11.093 4.693 22.187 12.373 30.293 8.107 7.68 19.2 12.373 30.293 12.373 11.093 0 22.187-4.693 30.293-12.373 7.68-8.107 12.373-19.2 12.373-30.293h85.333c23.552 0 42.667-19.115 42.667-42.667v-128c0-33.365 13.611-68.693 90.965-179.157 38.187-54.4 58.368-117.845 58.368-183.509 0-176.469-143.531-320-320-320zM618.667 810.667h-170.667v-42.667h170.667v42.667zM725.12 497.195c-62.549 89.301-91.435 140.331-101.76 185.472h-47.36v-85.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333h-47.488c-10.24-43.947-38.315-93.867-98.389-180.053-0.939-1.877-2.048-3.712-3.285-5.504-28.032-39.851-42.837-86.357-42.837-134.443 0-129.408 105.259-234.667 234.667-234.667s234.667 105.259 234.667 234.667c0 48.043-14.805 94.549-42.88 134.528z"></path>
                </svg>
                </div>
                <div className="infraestructura-text-container">
                <span className="infraestructura-heading Card-Heading">
                    Hardware y Software
                </span>
                <span className="infraestructura-text Card-Text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra finibus ornare. Curabitur non venenatis nisl. Maecenas sem tellus, mollis nec malesuada non, semper nec felis. Duis mattis risus eros, sit amet placerat erat euismod eu. Cras ac ipsum ante. 
                </span>
                </div>
                    </div>
                    <div className="infraestructura-features-card">
                        <div className="infraestructura-icon-container">
                        <svg viewBox="0 0 1024 1024" className="infraestructura-icon">
                            <path d="M533.333 234.667c-11.776 0-21.333 9.557-21.333 21.333s9.557 21.333 21.333 21.333c46.208 0 83.797 37.589 83.797 83.797 0 11.776 9.557 21.333 21.333 21.333s21.333-9.557 21.333-21.333c0-69.717-56.747-126.464-126.464-126.464z"></path>
                            <path d="M533.333 42.667c-176.469 0-320 143.531-320 320 0 63.403 18.773 124.672 54.357 177.707 0.981 2.005 2.091 3.925 3.413 5.76 77.867 111.189 91.563 146.347 91.563 179.2v128c0 23.552 19.115 42.667 42.667 42.667h85.333c0 11.093 4.693 22.187 12.373 30.293 8.107 7.68 19.2 12.373 30.293 12.373 11.093 0 22.187-4.693 30.293-12.373 7.68-8.107 12.373-19.2 12.373-30.293h85.333c23.552 0 42.667-19.115 42.667-42.667v-128c0-33.365 13.611-68.693 90.965-179.157 38.187-54.4 58.368-117.845 58.368-183.509 0-176.469-143.531-320-320-320zM618.667 810.667h-170.667v-42.667h170.667v42.667zM725.12 497.195c-62.549 89.301-91.435 140.331-101.76 185.472h-47.36v-85.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333h-47.488c-10.24-43.947-38.315-93.867-98.389-180.053-0.939-1.877-2.048-3.712-3.285-5.504-28.032-39.851-42.837-86.357-42.837-134.443 0-129.408 105.259-234.667 234.667-234.667s234.667 105.259 234.667 234.667c0 48.043-14.805 94.549-42.88 134.528z"></path>
                        </svg>
                        </div>
                        <div className="infraestructura-text-container">
                        <span className="infraestructura-heading Card-Heading">Telefonía</span>
                        <span className="infraestructura-text Card-Text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra finibus ornare. Curabitur non venenatis nisl. Maecenas sem tellus, mollis nec malesuada non, semper nec felis. Duis mattis risus eros, sit amet placerat erat euismod eu. Cras ac ipsum ante. 
                        </span>
                        </div>
                    </div>
                    <div className="infraestructura-features-card">
                        <div className="infraestructura-icon-container">
                        <svg viewBox="0 0 1024 1024" className="infraestructura-icon">
                            <path d="M533.333 234.667c-11.776 0-21.333 9.557-21.333 21.333s9.557 21.333 21.333 21.333c46.208 0 83.797 37.589 83.797 83.797 0 11.776 9.557 21.333 21.333 21.333s21.333-9.557 21.333-21.333c0-69.717-56.747-126.464-126.464-126.464z"></path>
                            <path d="M533.333 42.667c-176.469 0-320 143.531-320 320 0 63.403 18.773 124.672 54.357 177.707 0.981 2.005 2.091 3.925 3.413 5.76 77.867 111.189 91.563 146.347 91.563 179.2v128c0 23.552 19.115 42.667 42.667 42.667h85.333c0 11.093 4.693 22.187 12.373 30.293 8.107 7.68 19.2 12.373 30.293 12.373 11.093 0 22.187-4.693 30.293-12.373 7.68-8.107 12.373-19.2 12.373-30.293h85.333c23.552 0 42.667-19.115 42.667-42.667v-128c0-33.365 13.611-68.693 90.965-179.157 38.187-54.4 58.368-117.845 58.368-183.509 0-176.469-143.531-320-320-320zM618.667 810.667h-170.667v-42.667h170.667v42.667zM725.12 497.195c-62.549 89.301-91.435 140.331-101.76 185.472h-47.36v-85.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333h-47.488c-10.24-43.947-38.315-93.867-98.389-180.053-0.939-1.877-2.048-3.712-3.285-5.504-28.032-39.851-42.837-86.357-42.837-134.443 0-129.408 105.259-234.667 234.667-234.667s234.667 105.259 234.667 234.667c0 48.043-14.805 94.549-42.88 134.528z"></path>
                        </svg>
                        </div>
                        <div className="infraestructura-text-container">
                        <span className="infraestructura-heading Card-Heading">
                            Servicio técnico
                        </span>
                        <span className="infraestructura-text Card-Text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra finibus ornare. Curabitur non venenatis nisl. Maecenas sem tellus, mollis nec malesuada non, semper nec felis. Duis mattis risus eros, sit amet placerat erat euismod eu. Cras ac ipsum ante. 
                        </span>
                        </div>
                    </div>
                    <div className="infraestructura-features-card">
                        <div className="infraestructura-icon-container">
                        <svg viewBox="0 0 1024 1024" className="infraestructura-icon">
                            <path d="M533.333 234.667c-11.776 0-21.333 9.557-21.333 21.333s9.557 21.333 21.333 21.333c46.208 0 83.797 37.589 83.797 83.797 0 11.776 9.557 21.333 21.333 21.333s21.333-9.557 21.333-21.333c0-69.717-56.747-126.464-126.464-126.464z"></path>
                            <path d="M533.333 42.667c-176.469 0-320 143.531-320 320 0 63.403 18.773 124.672 54.357 177.707 0.981 2.005 2.091 3.925 3.413 5.76 77.867 111.189 91.563 146.347 91.563 179.2v128c0 23.552 19.115 42.667 42.667 42.667h85.333c0 11.093 4.693 22.187 12.373 30.293 8.107 7.68 19.2 12.373 30.293 12.373 11.093 0 22.187-4.693 30.293-12.373 7.68-8.107 12.373-19.2 12.373-30.293h85.333c23.552 0 42.667-19.115 42.667-42.667v-128c0-33.365 13.611-68.693 90.965-179.157 38.187-54.4 58.368-117.845 58.368-183.509 0-176.469-143.531-320-320-320zM618.667 810.667h-170.667v-42.667h170.667v42.667zM725.12 497.195c-62.549 89.301-91.435 140.331-101.76 185.472h-47.36v-85.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333h-47.488c-10.24-43.947-38.315-93.867-98.389-180.053-0.939-1.877-2.048-3.712-3.285-5.504-28.032-39.851-42.837-86.357-42.837-134.443 0-129.408 105.259-234.667 234.667-234.667s234.667 105.259 234.667 234.667c0 48.043-14.805 94.549-42.88 134.528z"></path>
                        </svg>
                        </div>
                        <div className="infraestructura-text-container">
                        <span className="infraestructura-heading Card-Heading">
                            Videovigilancia
                        </span>
                        <span className="infraestructura-text Card-Text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra finibus ornare. Curabitur non venenatis nisl. Maecenas sem tellus, mollis nec malesuada non, semper nec felis. Duis mattis risus eros, sit amet placerat erat euismod eu. Cras ac ipsum ante. 
                        </span>
                        </div>
                    </div>
                    <div className="infraestructura-features-card">
                        <div className="infraestructura-icon-container">
                        <svg viewBox="0 0 1024 1024" className="infraestructura-icon">
                            <path d="M533.333 234.667c-11.776 0-21.333 9.557-21.333 21.333s9.557 21.333 21.333 21.333c46.208 0 83.797 37.589 83.797 83.797 0 11.776 9.557 21.333 21.333 21.333s21.333-9.557 21.333-21.333c0-69.717-56.747-126.464-126.464-126.464z"></path>
                            <path d="M533.333 42.667c-176.469 0-320 143.531-320 320 0 63.403 18.773 124.672 54.357 177.707 0.981 2.005 2.091 3.925 3.413 5.76 77.867 111.189 91.563 146.347 91.563 179.2v128c0 23.552 19.115 42.667 42.667 42.667h85.333c0 11.093 4.693 22.187 12.373 30.293 8.107 7.68 19.2 12.373 30.293 12.373 11.093 0 22.187-4.693 30.293-12.373 7.68-8.107 12.373-19.2 12.373-30.293h85.333c23.552 0 42.667-19.115 42.667-42.667v-128c0-33.365 13.611-68.693 90.965-179.157 38.187-54.4 58.368-117.845 58.368-183.509 0-176.469-143.531-320-320-320zM618.667 810.667h-170.667v-42.667h170.667v42.667zM725.12 497.195c-62.549 89.301-91.435 140.331-101.76 185.472h-47.36v-85.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v85.333h-47.488c-10.24-43.947-38.315-93.867-98.389-180.053-0.939-1.877-2.048-3.712-3.285-5.504-28.032-39.851-42.837-86.357-42.837-134.443 0-129.408 105.259-234.667 234.667-234.667s234.667 105.259 234.667 234.667c0 48.043-14.805 94.549-42.88 134.528z"></path>
                        </svg>
                        </div>
                        <div className="infraestructura-text-container">
                        <span className="infraestructura-heading Card-Heading">
                            Instalación de equipamiento informático
                        </span>
                        <span className="infraestructura-text Card-Text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra finibus ornare. Curabitur non venenatis nisl. Maecenas sem tellus, mollis nec malesuada non, semper nec felis. Duis mattis risus eros, sit amet placerat erat euismod eu. Cras ac ipsum ante. 
                        </span>
                        </div>
                    </div>
                </div>
                <table className="partner-table">
                    <tr>
                        <th>
                            Somos partners de
                        </th>
                        <div className="partner-list">
                            <td>
                                <img src="files/microsoft_logo.png"></img>
                            </td>
                            <td>
                                <img src="files/lenovo.svg"></img>
                            </td>
                            <td>
                                <img src="files/Dell_Logo.svg"></img>
                            </td>
                            <td>
                                <img src="files/hplogo.svg"></img>
                            </td>
                        </div>
                    </tr>
                </table>
                <a href="/informacin-de-contacto" className="navbar-container-cta-btn Anchor button">Solicítanos información</a>
            </InfoContainer>
        </SiteContainer>
    )
}

export default Infraestructura