import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './kit-digital.css'

const KitDigital = (props) => {
  return (
    <div className="kit-digital-container">
      <Helmet>
        <title>Kit Digital - ConTel - Convergencia de Telecomunicaciones</title>
        <meta property="og:title" content="Kit Digital - ConTel - Convergencia de Telecomunicaciones" />
      </Helmet>
      <NavbarContainer rootClassName="navbar-container-root-class-name"></NavbarContainer>
      <div className="kit-digital-container1">
        <h1 style={{textAlign: "center"}}>
          Kit Digital
        </h1>
        <div className="kit-digital-content-sbs">
          <div className="kit-digital-container-left">
            <h2 className="kit-digital-text03">
              <span>¿Qué es?</span>
              <br></br>
            </h2>
            <span className="kit-digital-text06">
              <span>
                El Kit Digital es una iniciativa del Gobierno de España, que tiene
                como objetivo subvencionar la implantación de soluciones digitales
                disponibles en el mercado para conseguir un avance significativo
                en el nivel de madurez digital.
              </span>
              <br></br>
            </span>
            <h2 className="kit-digital-text09">
              <span>¿A quién va dirigido?</span>
              <br></br>
            </h2>
            <span className="kit-digital-text12">
              <span>
                Las soluciones digitales que proporciona el Kit Digital están
                orientadas a las necesidades de las pequeñas empresas,
                microempresas y trabajadores autónomos, que pertenezcan a
                cualquier sector o tipología de negocio.​
              </span>
            </span>
            <h2 className="kit-digital-text15">
              <span>¿Cómo conseguirlo?</span>
            </h2>
            <span className="kit-digital-text18">
              Si cumples con las condiciones establecidas en las bases de la
              convocatoria de la ayuda del Kit Digital, podrás disponer de un bono
              digital que te permitirá acceder a las soluciones de digitalización.
              Descárgate la guía rápida para saber más.
            </span>
            <a
              href="https://www.acelerapyme.gob.es/sites/acelerapyme/files/2022-01/Kit_Digital_Gui%CC%81a_pymes_13-01-22.pdf"
              target="_blank"
              rel="noreferrer noopener"
              className="kit-digital-link"
            >
              Guía rápida
            </a>
          </div>
          <div className="kit-digital-container-right">
            <div className="kit-digital-steps">
              <h1 className="kit-digital-text19">Servicios de Kit Digital</h1>
              <div className="kit-digital-container3">
                  <div className="kit-digital-step">
                    <h1 className="kit-digital-text20">
                      <span>1</span>
                    </h1>
                    <h1 className="kit-digital-stepname">Ciberseguridad</h1>
                    <Link
                      to="/ciberseguridad"
                      className="kit-digital-navlink button"
                    >
                      Más información
                    </Link>
                  </div>
                  <div className="kit-digital-step">
                    <h1 className="kit-digital-text23">
                      <span>2</span>
                    </h1>
                    <h1 className="kit-digital-stepname">
                      <span className="kit-digital-text26">
                        Comunicaciones Seguras
                      </span>
                      <br></br>
                    </h1>
                    <Link
                      to="/comunicaciones-seguras"
                      className="kit-digital-navlink button"
                    >
                      Más información
                    </Link>
                  </div>
                  <div className="kit-digital-step">
                    <h1 className="kit-digital-text28">
                      <span>3</span>
                    </h1>
                    <h1 className="kit-digital-stepname">
                      <span>Servicios de Oficina Virtual</span>
                      <br></br>
                    </h1>
                    <Link
                      to="/oficina-virtual"
                      className="kit-digital-navlink button"
                    >
                      Más información
                    </Link>
                  </div>
                  <div className="kit-digital-step">
                    <h1 className="kit-digital-text28">
                      <span>4</span>
                    </h1>
                    <h1 className="kit-digital-stepname">
                      <span>Puesto de trabajo seguro</span>
                      <br></br>
                    </h1>
                    <Link
                      to="/puesto-de-trabajo-seguro"
                      className="kit-digital-navlink button"
                    >
                      Más información
                    </Link>
                  </div>
                </div>

            </div>
          </div>
        </div>
        <div className="contact-cta-btn-container-kit">
          <a href="/informacin-de-contacto" className="contact-cta-btn">Solicítanos información</a>
        </div>
        
        <img src="files/kitdigital.png" className="kitdigital-footer" alt="Logo Kit Digital"></img>
      </div>
      <FooterContainer rootClassName="footer-container-root-class-name1"></FooterContainer>
    </div>
  )
}

export default KitDigital
