import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'

import InfoContainer from '../components/info-container'

import './informacin-de-contacto.css'

const setMessage = (message) => {
  const msg = document.getElementById("msg");
  
  msg.textContent = message;
}

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const queryParams = new URLSearchParams(new FormData(document.getElementById("form-contacto"))).toString();
    const response = await fetch(`https://web-contel-contactus.azurewebsites.net/api/SendContactUs?${queryParams}`, {
      method: 'POST',
    });
      setMessage('¡El formulario se envió correctamente!');
  } catch (error) {
    setMessage('¡El formulario se envió correctamente!');
  }
};

const InformacinDeContacto = (props) => {
  return (
    <div className="informacin-de-contacto-container">
      <Helmet>
        <title>ConTel - Convergencia de Telecomunicaciones</title>
        <meta
          property="og:title"
          content="ConTel - Convergencia de Telecomunicaciones"
        />
      </Helmet>
      <NavbarContainer></NavbarContainer>
      <div className="informacin-de-contacto-banner">
        <h1>Contacta con nosotros
        </h1>
        <h2>¡Trabajemos juntos!</h2>
      </div>
      <div className="informacin-de-contacto-container1">
        <img id="info-img" src="/files/hero.jpg"></img>
        <InfoContainer>
          <div className="sbs-box">
            <div className="info-left">
              <div className="get-in-touch-text">
                Si deseas consultar más información sobre nuestros 
                servicios o quieres que hagamos una auditoría 
                de la infraestructura actual de tu empresa, no 
                dudes en ponerte en contacto con nosotros y uno de nuestros compañeros 
                le responderá en la mayor brevedad. 
              </div>
              <div className="get-in-touch-info">
                    <div className="get-in-touch-info-item">
                      <svg viewBox="0 0 1024 1024" className="icon">
                        <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                      </svg>
                      <div>
                        <span>Convergencia de Telecomunicaciones</span>
                        <br className="Section-Text"></br>
                        <span>C/ Marcelino González nº 4 Bajo</span>
                        <br className="Section-Text"></br>
                        <span>33209 Gijón</span>
                        <br className="Section-Text"></br>
                        <span>España</span>
                      </div>
                    </div>
                    <div className="get-in-touch-info-item">
                      <svg viewBox="0 0 1024 1024" className="icon">
                        <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                      </svg>
                      <span>web@contelnet.com</span>
                    </div>
                    <div className="get-in-touch-info-item">
                      <svg
                        viewBox="0 0 804.5714285714286 1024"
                        className="icon"
                      >
                        <path d="M804.571 708.571c0 20.571-9.143 60.571-17.714 79.429-12 28-44 46.286-69.714 60.571-33.714 18.286-68 29.143-106.286 29.143-53.143 0-101.143-21.714-149.714-39.429-34.857-12.571-68.571-28-100-47.429-97.143-60-214.286-177.143-274.286-274.286-19.429-31.429-34.857-65.143-47.429-100-17.714-48.571-39.429-96.571-39.429-149.714 0-38.286 10.857-72.571 29.143-106.286 14.286-25.714 32.571-57.714 60.571-69.714 18.857-8.571 58.857-17.714 79.429-17.714 4 0 8 0 12 1.714 12 4 24.571 32 30.286 43.429 18.286 32.571 36 65.714 54.857 97.714 9.143 14.857 26.286 33.143 26.286 50.857 0 34.857-103.429 85.714-103.429 116.571 0 15.429 14.286 35.429 22.286 49.143 57.714 104 129.714 176 233.714 233.714 13.714 8 33.714 22.286 49.143 22.286 30.857 0 81.714-103.429 116.571-103.429 17.714 0 36 17.143 50.857 26.286 32 18.857 65.143 36.571 97.714 54.857 11.429 5.714 39.429 18.286 43.429 30.286 1.714 4 1.714 8 1.714 12z"></path>
                      </svg>
                      <span>985990676</span>
                  </div>
              </div>
            </div>
            <div className="separator"></div>
            <div className="info-right">
                <h1>Contáctanos</h1>
                <form id="form-contacto" onSubmit={handleSubmit}>
                  <input type="email" name="email" 
                  placeholder="Introduce aquí tu correo electrónico*" 
                  className="form-input" required></input>
                  <input type="text" name="nombre-empresa" 
                  placeholder="Introduce aquí el nombre de la empresa" 
                  className="form-input"></input>
                  <input type="tel" name="telefono" 
                  placeholder="Introduce tu teléfono" 
                  className="form-input"></input>
                  <select name="asunto" className="form-input">
                    <option value="" disabled selected>Selecciona el motivo de tu consulta</option>
                    <option value="comercial">Comercial</option>
                    <option value="técnico">Técnico</option>
                    <option value="administrativo">Administrativo</option>
                    <option value="trabaja">Trabaja con nosotros</option>
                  </select>
                  <textarea type="text" name="mensaje" 
                  placeholder="Escribe tu mensaje"
                  className="form-input"
                  id="message-box"></textarea>
                  <input type="checkbox" required id="lopd"></input>
                  <label id="lopd-label" for="lopd">He leído y acepto la <a style={{color: "blue"}} href="/poltica-de-privacidad">política de privacidad</a></label>
                  <input type="submit" id="form-submit"></input>
                  <h2 id="msg"></h2>
                </form>
            </div>
          </div>
        </InfoContainer>
      </div>
      
      <FooterContainer rootClassName="footer-container-root-class-name5"></FooterContainer>
    </div>
  )
}

export default InformacinDeContacto
