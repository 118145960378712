import React from 'react';

import InfoContainer from '../components/info-container';
import SiteContainer from '../components/site-container';

import './nosotros.css';

const Nosotros = (props) => {
    return (
      <SiteContainer>
        <InfoContainer>
          <div>
            <h1>Nosotros</h1>
            <div className="nosotros-content-box-content">
              <div className="div-nosotros-left">
                <p>
                  <strong>Contel, Convergencia de Telecomunicaciones</strong>, nace 
                  en <strong>2003</strong> bajo el claro objetivo de ofrecer a sus 
                  clientes un servicio de telecomunicaciones 
                  diferente, de calidad y cercanía.  Somos una empresa
                  orientada al dar servicio y soluciones tecnológicas al cliente.
                </p>
                <p>
                  Para ello, a lo largo de los años, hemos ido avanzando en los 
                  servicios ofrecidos y adaptándonos a las necesidades de cada 
                  cliente, siempre desde una constante innovación tecnológica.
                </p>
                <p>
                  Nuestra prioridad es el servicio al cliente, acompañándolo 
                  en su evolución y dándoles soluciones  a medida en función 
                  de sus necesidades,  buscamos realizar 
                  un servicio 360ª, <u><em><strong>aconsejando</strong></em></u> a cada cliente según sus necesidades y 
                  adaptándonos a sus peticiones.
                </p>
                <p>
                  Para ellos hemos ido adaptando nuestros servicios 
                  a las necesidades del cliente, el cliente es el centro
                  y forjando alianzas estratégicas con empresas tecnológicas 
                  como Microsoft, Dell, HP, Bitdefender, Acronis…
                </p>
                <p>
                  Nuestra máxima es dar un servicio global
                  y profesional a las Pymes, ayudándolas con asesoramiento 
                  personalizado, servicio técnico profesional y ofreciéndoles 
                  soluciones a medida.
                </p>
                <div className="contact-cta-btn-container-nosotros">
        <a href="/informacin-de-contacto" className="contact-cta-btn">Solicítanos información</a>
      </div>
              </div>
              <div className="separator"></div>
              <div className="div-nosotros-right">
               <img src="/files/hero.jpg"></img>
              </div>
            </div>
          </div>
        </InfoContainer>
      </SiteContainer>
    )
}

export default Nosotros
