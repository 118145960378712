import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './poltica-de-privacidad.css'

const PolticaDePrivacidad = (props) => {
  return (
    <div className="poltica-de-privacidad-container">
      <Helmet>
        <title>Poltica-de-Privacidad - ConTel - Convergencia de Telecomunicaciones</title>
        <meta
          property="og:title"
          content="Poltica-de-Privacidad - ConTel - Convergencia de Telecomunicaciones"
        />
      </Helmet>
      <NavbarContainer rootClassName="navbar-container-root-class-name3"></NavbarContainer>
      <div className="poltica-de-privacidad-banner">
        <h1 className="poltica-de-privacidad-text">
          <span>Política de Privacidad</span>
          <br></br>
        </h1>
      </div>
      <span className="poltica-de-privacidad-text03">
        <span>POLÍTICA DE PRIVACIDAD:</span>
        <br></br>
        <br></br>
        <span>-IDENTIFICACIÓN DEL RESPONSABLE DEL FICHERO Y TRATAMIENTO:</span>
        <br></br>
        <span>
          De conformidad con lo dispuesto en la Ley Orgánica 15/1999, de
          Protección de Datos de Carácter Personal (LOPD) y su normativa de
          desarrollo, el responsable del sitio web, en cumplimiento de lo
          dispuesto en el art. 5 y 6 de la LOPD, informa a todos los usuarios
          del sitio web que faciliten o vayan a facilitar sus datos personales
          como consecuencia de la relación contractual, o cualesquiera otras
          consultas, transacciones, comunicaciones, etc., que estos serán
          incorporados en un fichero automatizado titularidad y responsabilidad
          de Convergencia de Telecomunicaciones S.L que se encuentra debidamente
          inscrito en la Agencia Española de Protección de Datos.
        </span>
        <br></br>
        <br></br>
        <span>-OBJETO DEL TRATAMIENTO DE DATOS:</span>
        <br></br>
        <span>
          Por dato de carácter personal se entiende cualquier información
          concerniente a personas físicas identificadas o identificables, lo
          cual incluye el dato del correo electrónico y/o la dirección IP en el
          caso en que a través de esta información se identifique
          inequívocamente a una persona.
        </span>
        <br></br>
        <br></br>
        <span>
          Como usuario de los sitios web de Convergencia de Telecomunicaciones
          S.L así como de sus servicios y contenidos, podemos recoger datos
          sobre ti (sin que esta enumeración se entienda limitativa), cuando te
          registras en nuestros sitios, creas tu perfil, usas nuestros chats y
          foros, publicas fotos e imágenes y otros contenidos, cumplimentas
          formularios de recogida de datos, participas en campañas y
          promociones, nos envías un correo electrónico, y en general cuando
          para utilizar nuestros sitios o los servicios a través de los mismos
          nos comunicas tus datos personales.
        </span>
        <br></br>
        <br></br>
        <span>
          Cuando accedes a los sitios de Convergencia de Telecomunicaciones S.L,
          no estás obligado a proporcionar información personal. Cualquier
          comunicación de datos que realices a Convergencia de
          Telecomunicaciones S.L es porque voluntariamente has decidido una
          navegación o uso personalizado de nuestros servicios.
        </span>
        <br></br>
        <br></br>
        <span>
          En estos casos debes tener en cuenta que si te comunicas con
          Convergencia de Telecomunicaciones S.L a través de cualquier medio
          como teléfono, fax o mail, y nos comunicas tus datos personales, ello
          implica que nos autorizas al tratamiento de los mismos y por tanto
          prestas tu consentimiento libre, inequívoco, específico, informado y
          expreso para la inclusión de tus datos personales en un fichero de
          nuestra responsabilidad para que procedamos a atender tu comunicación.
        </span>
        <br></br>
        <br></br>
        <span>
          En caso en que contactes con Convergencia de Telecomunicaciones S.L
          para realizar consultas o solicitar información, dicho tratamiento de
          datos se realizará con la finalidad de atender y contestar las
          comunicaciones recibidas así como enviar la información solicitada a
          las citadas entidades.
        </span>
        <br></br>
        <br></br>
        <span>-INFORMACIÓN SOBRE LAS FINALIDADES DEL TRATAMIENTO DE DATOS</span>
        <br></br>
        <span>
          Los usuarios, mediante el acceso al presente sitio Web, aceptan
          expresamente y de forma libre e inequívoca que sus datos personales
          sean tratados por parte del prestador para realizar las siguientes
          finalidades:
        </span>
        <br></br>
        <br></br>
        <span>
          1. Remisión de comunicaciones comerciales publicitarias por e-mail,
          fax, SMS, MMS, comunidades sociales o cualesquier otro medio
          electrónico o físico, presente o futuro, que posibilite realizar
          comunicación comerciales. Dichas comunicaciones comerciales serán
          relacionadas sobre productos o servicios ofrecidos por el prestador,
          así como por parte de los colaboradores o parthners con los que éste
          hubiera alcanzado algún acuerdo de promoción comercial entre sus
          clientes. En este caso, los terceros nunca tendrán acceso a los datos
          personales. En todo caso las comunicaciones comerciales serán
          realizadas por parte del prestador y serán de productos y servicios
          relacionados con el sector del prestador.
        </span>
        <br></br>
        <br></br>
        <span>2. Realizar estudios estadísticos.</span>
        <br></br>
        <br></br>
        <span>
          3. Tramitar encargos, solicitudes o cualquier tipo de petición que sea
          realizada por el usuario a través de cualquiera de las formas de
          contacto que se ponen a disposición del usuario en el sitio web de La
          empresa.
        </span>
        <br></br>
        <br></br>
        <span>
          El usuario y/o cliente puede revocar este consentimiento en cualquier
          momento, sin efectos retroactivos.
        </span>
        <br></br>
        <span>
          La empresa informa y garantiza expresamente a los usuarios que sus
          datos personales no serán cedidos en ningún caso a terceras compañías,
          y que siempre que fuera a realizarse algún tipo de cesión de datos
          personales, de forma previa, se solicitaría el consentimiento expreso,
          informado, e inequívoco por parte de los titulares.
        </span>
        <br></br>
        <br></br>
        <span>
          Al margen de lo anterior, Convergencia de Telecomunicaciones S.L
          únicamente tiene prevista la realización de cesiones o comunicaciones
          de tus datos que en razón del artículo 11.2.c. de la LOPD deban
          realizar para atender sus obligaciones con las Administraciones
          Públicas en los casos que así se requiera de acuerdo con la
          Legislación vigente en cada materia en cada momento y en su caso
          igualmente, a otros órganos como Fuerzas y Cuerpos de Seguridad del
          Estado, Jueces, Ministerio Fiscal, Tribunales, Tribunal de Cuentas o
          Defensor del Pueblo.
        </span>
        <br></br>
        <br></br>
        <span>
          Igualmente te informamos de que cualquier otra cesión de datos que
          debamos realizar, será puesta en tu conocimiento cuando así lo prevea
          la normativa vigente indicándote de modo expreso, preciso e inequívoco
          quienes son los destinatarios de la información, la finalidad a que se
          destinarán los datos, y la naturaleza de los datos cedidos, o en su
          caso cuando la normativa vigente lo establezca, previamente te
          solicitaremos el consentimiento inequívoco, específico e informado de
          forma previa a la cesión o comunicación de los datos.
        </span>
        <br></br>
        <br></br>
        <span>
          Todos los datos solicitados a través del sitio web son obligatorios,
          ya que son necesarios para la prestación de un servicio óptimo al
          usuario. En caso de que no sean facilitados todos los datos, el
          prestador no garantiza que la información y servicios facilitados sean
          completamente ajustados a sus necesidades.
        </span>
        <br></br>
        <br></br>
        <span>
          -EJERCICIO DE LOS DERECHOS DE OPOSICIÓN, ACCESO, RECTIFICACIÓN Y
          CANCELACIÓN DE LOS DATOS:
        </span>
        <br></br>
        <br></br>
        <span>
          Te informamos de que, al amparo del artículo 21.2 y 22.1 de la LSSICE
          tienes derecho a oponerte al tratamiento de tus datos con fines
          promocionales para la recepción de comunicaciones comerciales así como
          a revocar en cualquier momento el consentimiento prestado al efecto,
          en cualquiera de los casos (oposición y revocación) con la simple
          notificación de su voluntad a Convergencia de Telecomunicaciones S.L.
        </span>
        <br></br>
        <br></br>
        <span>
          Para ello te informamos de la posibilidad de ejercitar tus derechos de
          acceso, rectificación, cancelación y oposición mediante solicitud
          escrita dirigida a Convergencia de Telecomunicaciones S.L, en los
          términos dispuestos en la legislación vigente. Por ello, de
          conformidad con lo dispuesto en la Ley Orgánica 15/1999, de Protección
          de Datos de Carácter Personal (LOPD) podrá ejercer sus derechos de
          acceso, rectificación, cancelación y oposición, remitiendo una
          solicitud expresa, junto a una copia de su DNI, a través de los
          siguientes medios:
        </span>
        <br></br>
        <br></br>
        <span>1. E-mail: web@contelnet.com</span>
        <br></br>
        <span>
          2. Correo Postal: Convergencia de Telecomunicaciones S.L, C/ Marcelino
          González Nº 4 Bajo, 33209 Gijón Asturias.
        </span>
        <br></br>
        <br></br>
        <span>
          También puedes enviarnos tu petición firmada y copia del DNI por
          correo electrónico a través de la siguiente dirección
          web@contelnet.com
        </span>
        <br></br>
        <br></br>
        <span>
          El ejercicio de estos derechos es personalísimo por lo que, junto con
          tu petición escrita indicando el derecho que deseas ejercitar tendrás
          que enviarnos una fotocopia de tu D.N.I. por las dos caras o de un
          documento equivalente válido en derecho que acredite tu identidad.
        </span>
        <br></br>
        <br></br>
        <span>
          Tu petición será tenida en cuenta por Convergencia de
          Telecomunicaciones S.L para garantizar el ejercicio de tus derechos en
          los ficheros de los que cada una de ellas es responsable a cuyos
          efectos tus datos personales y petición que ejercitas podrán ser
          comunicados entre las citadas entidades
        </span>
        <br></br>
        <br></br>
        <span>-CONFIDENCIALIDAD Y MEDIDAS DE SEGURIDAD:</span>
        <br></br>
        <br></br>
        <span>
          Del mismo modo, el prestador ha adoptado todas las medidas técnicas y
          organizativas necesarias para garantizar la seguridad e integridad de
          los datos de carácter personal de cuyo tratamiento es responsable, así
          como para evitar su pérdida, alteración y/o acceso por parte de
          terceros no autorizados; todo ello de conformidad con lo dispuesto en
          la Ley Orgánica 15/1999, de Protección de Datos de Carácter Personal
          (LOPD), y su normativa de desarrollo.
        </span>
        <br></br>
        <span>
          Es muy importante que conozcas que, salvo la existencia de una
          representación legalmente constituida, no puedes actuar en nombre de
          terceros.
        </span>
        <br></br>
        <span>Asimismo, debes tener en cuenta que no puedes:</span>
        <br></br>
        <span>
          · Utilizar la identidad de otra persona ni hacerte pasar por ella.
        </span>
        <br></br>
        <span>
          · Comunicar o publicar información – cualquier tipo de datos o
          imágenes de terceros- si no tienes su autorización. En caso de que
          publiques información de terceros o proporciones a terceros datos de
          otra persona además de contar con su autorización, debes informarle de
          la finalidad de la comunicación o publicación de sus datos y de la
          posibilidad de ejercicio de sus derechos para acceder, rectificar,
          cancelar y oponerse al tratamiento.
        </span>
        <br></br>
        <span>
          En caso en que nos comuniques datos de otras personas a través de
          correo electrónico u otros medios de comunicación, ten en cuenta de
          que serás el único responsable frente a los terceros titulares de los
          datos y frente a Convergencia de Telecomunicaciones S.L de cualquier
          daño, directo y/o indirecto que causes por el uso de datos personales
          de otra persona cuando sean falsos, erróneos, no actuales, inadecuados
          o impertinentes.
        </span>
        <br></br>
        <span>
          En este sitio web, los datos se recopilan y almacenan de forma anónima
          para fines de marketing y optimización mediante la tecnología de
          (www.etracker.de). A continuación, se crean los perfiles de uso a
          partir de estos datos utilizando un seudónimo. Para este fin, pueden
          utilizarse las cookies, que recopilan y almacenan los datos de forma
          anónima. Los datos no se utilizan para identificar al usuario de este
          sitio web de forma personal ni se combinan con datos personales. Es
          posible revocar el consentimiento para recopilar y almacenar datos
          personales en cualquier momento y para siempre.
        </span>
        <br></br>
        <br></br>
      </span>
      <FooterContainer rootClassName="footer-container-root-class-name3"></FooterContainer>
    </div>
  )
}

export default PolticaDePrivacidad
