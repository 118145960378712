import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './puestots.css'

const Puestots = (props) => {
  return (
    <div className="puestots-container">
      <Helmet>
        <title>ConTel - Convergencia de Telecomunicaciones</title>
        <meta property="og:title" content="Puesto de Trabajo Seguro - ConTel" />
      </Helmet>
      <NavbarContainer rootClassName="navbar-container-root-class-name2"></NavbarContainer>
      <div className="puestots-banner">
        <h1 className="puestots-text">Kit Digital: Puesto de Trabajo seguro</h1>
      </div>
      <div className="puestots-container1">
      <div>
<div className="puestots-container2">
<div>
<div>
<div>
<p>Si tienes una microempresa perteneciente al segmento III, el principal objetivo de esta categor&iacute;a es proporcionarte un puesto de trabajo seguro con el fin de potenciar o mejorar tu productividad.</p>
</div>
</div>
<div>
<div>
<h2>Funcionalidades y servicios</h2>
<div>
<ul>
<li>
<p>Recibir&aacute;s un dispositivo de hardware que deber&aacute; incluir integrado el producto, una licencia y un encriptado/cifrado en reposo que asegure la privacidad de los datos y documentos almacenados en el dispositivo.</p>
</li>
<li>
<p>Este dispositivo podr&aacute; ser un ordenador port&aacute;til o de sobremesa nuevo. Adem&aacute;s, deber&aacute; tener como m&iacute;nimo las siguientes caracter&iacute;sticas de hardware:</p>
</li>
</ul>
<ol>
<li>
<p><strong>Requisitos m&iacute;nimos del dispositivo hardware:</strong></p>
</li>
</ol>
<ul>
<li>
<p><strong>Un procesador </strong>de m&iacute;nimo de 4 n&uacute;cleos f&iacute;sicos y una velocidad de CPU<em> clock</em> m&aacute;xima de al menos 2.9 GHz y&nbsp;haber obtenido una puntuaci&oacute;n m&iacute;nima de 15.000 en PassMark Software, con fecha 13 de mayo de 2024.</p>
</li>
<li>
<p><strong>Una</strong><strong> memoria RAM</strong> m&iacute;nima de 16GB DDR4.</p>
</li>
<li>
<p><strong>Un almacenamiento SSD</strong> m&iacute;nimo de 512 GB. Adem&aacute;s, tus datos estar&aacute;n protegidos con encriptado, la contrase&ntilde;a ser&aacute; proporcionada por el Agente Digitalizador.</p>
</li>
<li>
<p><strong>Un sistema operativo </strong>preinstalado y licenciado de f&aacute;brica.</p>
</li>
<li>
<p><strong>Tarjetas gr&aacute;ficas, de red y de sonido </strong>integradas.</p>
</li>
<li>
<p><strong>Interfaces de entrada/salida, </strong>como puertos USB o de entrada de micr&oacute;fono, que podr&aacute;n estar integrados en el dispositivo o a trav&eacute;s de un replicador de puertos.</p>
</li>
<li>
<p>Una <strong>p</strong><strong>antalla, </strong>si es un ordenador port&aacute;til ser&aacute; de m&iacute;nimo 13 pulgadas; <strong>rat&oacute;n</strong> &oacute;ptico, en caso de port&aacute;tiles, se aceptar&aacute; el rat&oacute;n integrado o touchpad;<strong> webcam </strong>con resoluci&oacute;n m&iacute;nima HD y un lector de tarjetas smartcard (DNI-e) con interfaz USB.</p>
</li>
<li>
<p>En el caso particular de los ordenadores de sobremesa contar&aacute;s con un <strong>monitor</strong> de m&iacute;nimo 23 pulgadas, resoluci&oacute;n m&iacute;nima Full HD y tecnolog&iacute;a de visualizaci&oacute;n LCD, LED o equivalente, al menos un HDMI y etiqueta energ&eacute;tica de la Uni&oacute;n Europea; un <strong>teclado</strong> y <strong>cableado </strong>tanto de alimentaci&oacute;n de dispositivos como HDMI.</p>
</li>
<li>
<p>En el caso de los ordenadores de sobremesa y port&aacute;tiles, deber&aacute;n contar con al menos una <strong>certificaci&oacute;n </strong>de los siguientes tipos (o similar):</p>
<ul>
<li>
<p>ENERGY STAR&reg;.</p>
</li>
<li>
<p>EPEAT&trade; Silver Registered.</p>
</li>
<li>
<p>TCO.</p>
</li>
</ul>
</li>
<li>
<p><strong>Certificaci&oacute;n MIL STD 810H</strong> o equivalente. Deber&aacute;n cumplir un m&iacute;nimo de 5 m&eacute;todos, entre ellos: alta temperatura, baja temperatura y humedad.</p>
</li>
</ul>
<ol start="2">
<li>
<p><strong>Requisitos espec&iacute;ficos de Publicidad:</strong></p>
</li>
</ol>
<ul>
<li>
<p>El dispositivo deber&aacute; contar con serigraf&iacute;a o etiqueta pegada &uacute;nicamente en el port&aacute;til, ordenador o monitor.</p>
</li>
<li>
<p>Los logos que deber&aacute;n contener ambas opciones ser&aacute;n los siguientes:</p>
</li>
<li>
<p>El emblema de la Uni&oacute;n Europea (disponible en el link https://planderecuperacion.gob.es/identidad-visual), que indique &laquo;Financiado por la Uni&oacute;n Europea-Next Generation EU&raquo;.</p>
</li>
<li>
<p>El logo del Plan de Recuperaci&oacute;n, Transformaci&oacute;n y Resiliencia.</p>
</li>
<li>
<p>El logo del Ministerio para la Transformaci&oacute;n Digital y de la Funci&oacute;n P&uacute;blica.</p>
</li>
<li>
<p>El logo de la Entidad P&uacute;blica Empresarial Red.es (Deber&aacute; aparecer como &laquo;Red.es&raquo;).</p>
</li>
</ul>
<ol start="3">
<li>
<p><strong>Requisitos m&iacute;nimos del servicio de soporte y atenci&oacute;n:</strong></p>
</li>
</ol>
<p>El Agente Digitalizador proporcionar&aacute; un<strong> servicio de Soporte y Atenci&oacute;n con las siguientes garant&iacute;as </strong>desde la entrega de los dispositivos hasta la finalizaci&oacute;n de la ejecuci&oacute;n de fase 2:</p>
<ul>
<li>
<p>El dispositivo se entregar&aacute; dentro de Espa&ntilde;a tras haber sido comprobado su correcto funcionamiento.</p>
</li>
<li>
<p>Como beneficiario, podr&aacute;s ser atendido por el servicio de soporte en r&eacute;gimen de 5 d&iacute;as laborables. Deber&aacute;s contar con un correo electr&oacute;nico para recibir los avisos de aver&iacute;as o incidencias, con un horario m&iacute;nimo del servicio de atenci&oacute;n a estas comunicaciones de 09:00 a 17:00 horas, de lunes a viernes.</p>
</li>
<li>
<p>Las reparaciones se har&aacute;n a trav&eacute;s de un soporte en remoto o in situ en la ubicaci&oacute;n donde se realiz&oacute; la entrega. Los costes derivados ser&aacute;n responsabilidad del digitalizador. Se incluyen las reparaciones por rotura o desperfectos y problemas de configuraci&oacute;n, siempre que est&eacute;n basados en un correcto uso del dispositivo.</p>
</li>
<li>
<p>El tiempo m&aacute;ximo de reparaci&oacute;n de la aver&iacute;a, entre la comunicaci&oacute;n de la incidencia y hasta la resoluci&oacute;n de esta, ser&aacute; de 5 d&iacute;as laborables. Si no se cumple este plazo se te otorgar&aacute; un equipo an&aacute;logo de sustituci&oacute;n.</p>
</li>
</ul>
<ol start="4">
<li>
<p><strong>Requisitos de ciberseguridad del Puesto de trabajo seguro:</strong></p>
</li>
</ol>
<p>La soluci&oacute;n de Puesto de trabajo seguro cumplir&aacute; con los siguientes requisitos m&iacute;nimos asociados a la categor&iacute;a de soluci&oacute;n<strong> de Ciberseguridad:</strong></p>
<ul>
<li>
<p><strong>Antimalware:</strong> tendr&aacute;s acceso a una herramienta que analice tu dispositivo, su memoria interna y los dispositivos de almacenamiento externos.</p>
</li>
<li>
<p><strong>Antispyware:</strong> se proporcionar&aacute; una herramienta que detecte y evite el malware esp&iacute;a.</p>
</li>
<li>
<p><strong>Correo seguro:</strong> contar&aacute;s con herramientas de an&aacute;lisis del correo electr&oacute;nico con las siguientes caracter&iacute;sticas:</p>
<ul>
<li>
<p><strong>Antispam</strong>, con detecci&oacute;n y filtro de correo no deseado.</p>
</li>
<li>
<p><strong>Antiphishing</strong>, con detecci&oacute;n de correos con enlaces o malware que se sospecha sirvan para robar credenciales.</p>
</li>
</ul>
</li>
<li>
<p><strong>Navegaci&oacute;n segura:</strong> contar&aacute;s con un control de contenidos y antiadware para evitar anuncios maliciosos.</p>
</li>
<li>
<p><strong>An&aacute;lisis y detecci&oacute;n de amenazas</strong>: la soluci&oacute;n te permitir&aacute; conocer el comportamiento de las amenazas existentes y nuevas.</p>
</li>
<li>
<p><strong>Monitorizaci&oacute;n de la red:</strong> tendr&aacute;s herramientas que analicen el tr&aacute;fico de red y alerten de amenazas.</p>
</li>
<li>
<p><strong>Configuraci&oacute;n inicial y actualizaciones de seguridad:</strong> se debe realizar una configuraci&oacute;n inicial para su correcto uso, con las respectivas actualizaciones de firmas de malware y otros datos para detecci&oacute;n de amenazas adem&aacute;s de las actualizaciones de software de seguridad peri&oacute;dicas requeridas.</p>
</li>
<li>
<p><strong>Requisitos especiales de formaci&oacute;n:</strong> adem&aacute;s de los requisitos de formaci&oacute;n comunes, la formaci&oacute;n que recibir&aacute;s incluir&aacute; una tutorizaci&oacute;n para la configuraci&oacute;n del software de seguridad, as&iacute; como un kit de concienciaci&oacute;n en ciberseguridad para complementar la soluci&oacute;n con habilidades de firewall humano.</p>
</li>
</ul>
<ol start="5">
<li>
<p><strong>Posibilidad de adquisici&oacute;n del hardware asociado:</strong></p>
</li>
</ol>
<ul>
<li>
<p>Una vez finalizada la fase II, podr&aacute;s adquirir la propiedad del hardware, previo pago al Agente Digitalizador Adherido del valor residual del equipamiento.</p>
</li>
<li>
<p>Esta ser&aacute; una decisi&oacute;n opcional para ti, pero obligatoria para el Agente Digitalizador Adherido.</p>
</li>
<li>
<p>En el caso de que no pagues el importe de compra acordado, el Agente Digitalizador Adherido podr&aacute; recuperar el equipamiento.</p>
</li>
<li>
<p>El Agente Digitalizador Adherido, al finalizar los 12 meses de prestaci&oacute;n del servicio correspondiente a la fase II, deber&aacute; informar, en su oferta del Cat&aacute;logo de Soluciones de Digitalizaci&oacute;n de Acelera Pyme, cu&aacute;l es el valor de compra del equipo. Dicho importe deber&aacute; constar reflejado en el Acuerdo de Prestaci&oacute;n de Soluciones de Digitalizaci&oacute;n.</p>
</li>
<li>
<p>Como m&aacute;ximo, el importe del valor de compra ser&aacute; del 15% del importe del acuerdo firmado (importe de la soluci&oacute;n).</p>
</li>
</ul>
<p><em>Aquellos beneficiarios que opten por la formalizaci&oacute;n de Acuerdos de la Categor&iacute;a de &laquo;Puesto de trabajo seguro&raquo;, deber&aacute;n tener validado uno o m&aacute;s Acuerdos de Prestaci&oacute;n de Soluciones de Digitalizaci&oacute;n por cualquier otra Categor&iacute;a de Soluci&oacute;n de Digitalizaci&oacute;n de entre las previstas en el art&iacute;culo 18.2 de las Bases Reguladoras.</em></p>
</div>

<h2>Porcentajes de ejecuci&oacute;n asociados a las fases</h2>
<div>
<ul>
<li>
<p>Primera: 80 %</p>
</li>
<li>
<p>Segunda: 20 %</p>
</li>
</ul>
<h2>Importe m&aacute;ximo de la ayuda</h2>
<div>
<ul>
<li>
<p>0 &lt; 3 empleados: 1.000&euro; (<em>incluye 1 dispositivo)</em></p>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</div> 
        <img src="files/kitdigital.png" className="kitdigital-footer" alt="Logo Kit Digital"></img>
      </div>
      <FooterContainer rootClassName="footer-container-root-class-name2"></FooterContainer>
    </div>
  )
}

export default Puestots
