import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './oficina-virtual.css'

const oficinaVirtual = (props) => {
  return (
    <div className="oficina-virtual-container">
      <Helmet>
        <title>ConTel - Convergencia de Telecomunicaciones</title>
        <meta property="og:title" content="ConTel - Convergencia de Telecomunicaciones" />
      </Helmet>
      <NavbarContainer rootClassName="navbar-container-root-class-name2"></NavbarContainer>
      <div className="oficina-virtual-banner">
        <h1 className="oficina-virtual-text">Kit Digital: Oficina Virtual</h1>
      </div>
      <div className="oficina-virtual-container1">
        <div className="oficina-virtual-container2">
          <h1>Oficina Virtual</h1>
          El principal objetivo de esta categoría es implantar en tu empresa soluciones interactivas y funcionales que permitan la colaboración más eficiente entre tus trabadores.
          
<h2>Funcionalidades y servicios</h2>

<div>
<ul>
	<li className="list-item">
	<p className="oficina-virtual-paragraph"> tendrás asistencia por parte del proveedor en el proceso creativo de ideación o gestión de equipos para la gestión colaborativa de proyectos, compartir recursos y/o conocimientos, dotar de herramientas para la interacción de los equipos en los procesos de ideación o resolución de problemas, así como la configuración y personalización de flujos de trabajo, tareas, etc.</p>
	</li>
	<li className="list-item">
	<p className="oficina-virtual-paragraph"> dispondrás de 1 TB de almacenamiento.</p>
	</li>
	<li className="list-item">
	<p className="oficina-virtual-paragraph"> Tu solución será compatible con dispositivos móviles.</p>
	</li>
	<li className="list-item">
	<p className="oficina-virtual-paragraph"> te permitirá organizar un calendario y tus tareas previstas.</p>
	</li>
</ul>
</div>

<h2>Porcentajes de ejecución asociados a las fases</h2>

<div>
<ul>
	<li className="list-item">
	<p className="oficina-virtual-paragraph">
	Primera: 70 %
	</p>
	</li>
	<li className="list-item">
	<p className="oficina-virtual-paragraph">
	Segunda: 30 %
	</p>
	</li>
</ul>
<h2>Importe máximo de la ayuda</h2>
<ul>
	<li className="list-item">
		<p className="oficina-virtual-paragraph">
		0 &lt; 3 empleados: 250€/usuario (hasta 2 usuarios)
		</p>
	</li>
	<li className="list-item">
		<p className="oficina-virtual-paragraph">
		3 &lt; 9 empleados: 250€/usuario (hasta 9 usuarios)
		</p>
	</li>
	<li className="list-item">
		<p className="oficina-virtual-paragraph">
		10 &lt; 50 empleados: 250€/usuario (hasta 48 usuarios)
		</p>
	</li>
</ul>

	<div>
		<h2>Descripción de la solución y precios desde:</h2>

		<p className="oficina-virtual-paragraph"><img alt="Office_365" src="/files/Office_365.JPG" className="pricing-img"></img></p>
	</div>
</div>

<div className="contact-cta-btn-container">
        <a href="/informacin-de-contacto" className="contact-cta-btn">Solicítanos información</a>
      </div>

          </div>
        <img src="files/kitdigital.png" className="kitdigital-footer" alt="Logo Kit Digital"></img>
      </div>
      <FooterContainer rootClassName="footer-container-root-class-name2"></FooterContainer>
    </div>
  )
}

export default oficinaVirtual
