import React from 'react'
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'


import PropTypes from 'prop-types'

import './navbar-container.css'

const NavbarContainer = (props) => {
  return (
    <div
      data-role="Header"
      className={`navbar-container-navbar-container ${props.rootClassName} `}
    >
      <div className="navbar-container-navbar">
        <div className="navbar-container-left-side">
        
          <Link to="/" className="navbar-container-navlink">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              loading="lazy"
              className="navbar-container-image"
            />
          </Link>
          <div className="navbar-container-links-container">
            <a href="/nosotros" className="navbar-container-link Anchor">
              {props.link1}
            </a>
          </div>
          <div className="navbar-container-links-container">
            <a href="/servicios-cloud" className="navbar-container-link Anchor">
              {props.link2}
            </a>
          </div>
          <div className="navbar-container-links-container">
            <a href="/kit-digital" className="navbar-container-link Anchor">
              {props.link4}
            </a>
          </div>
          <div className="navbar-container-links-container">
            <a href="/informacin-de-contacto" className="navbar-container-link Anchor">
              {props.link5}
            </a>
          </div>
        </div>
        <div className="navbar-container-right-side">
          <a
            href={props.linkCtaBtn}
            target="_blank"
            rel="noreferrer noopener"
            className="navbar-container-cta-btn Anchor button"
          >
            {props.ctaBtn}
          </a>
        </div>
        <div className="the-actual-burger-menu">
          <Menu>
            <div className="navbar-container-links-container">
              <a href="/nosotros" className="navbar-container-link Anchor">
                {props.link1}
              </a>
            </div>
            <div className="navbar-container-links-container">
              <a href="/servicios-cloud" className="navbar-container-link Anchor">
                {props.link2}
              </a>
            </div>
            <div className="navbar-container-links-container">
              <a href="/kit-digital" className="navbar-container-link Anchor">
                {props.link4}
              </a>
            </div>
            <div className="navbar-container-links-container">
              <a href="/informacin-de-contacto" className="navbar-container-link Anchor">
                {props.link5}
              </a>
            </div>
          </Menu>
        </div>
      </div>
      <div className="construction-notice">
        ⓘ Actualmente nuestra página se encuentra en construcción.
      </div>
    </div>
  )
}

NavbarContainer.defaultProps = {
  linkCtaBtn: 'https://get.anydesk.com/2e6IXGjd/AnyDesk_ConTel.exe',
  link1: 'Nosotros',
  link2: 'Servicios',
  link3: 'Infraestructura',
  link4: 'Kit Digital',
  link5: 'Contacta',
  link6: 'Centralita Cloud',
  imageSrc: '/files/logo.svg',
  ctaBtn: 'DESCARGA ANYDESK',
  rootClassName: '',
  imageAlt: 'Logo ConTel',
  imageSrc1: 'https://play.teleporthq.io/static/svg/default-img.svg',
}

NavbarContainer.propTypes = {
  linkCtaBtn: PropTypes.string,
  link: PropTypes.string,
  imageSrc: PropTypes.string,
  ctaBtn: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default NavbarContainer
