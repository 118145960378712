import React from 'react';

import { Helmet } from 'react-helmet';

import NavbarContainer from './navbar-container'
import FooterContainer from './footer-container'

import './site-container.css'

function SiteContainer (props) {
    return (
        <div className="site-container">
            <Helmet>
                <title>ConTel - Convergencia de Telecomunicaciones</title>
                <meta property="og:title" content="ConTel - Convergencia de Telecomunicaciones" />
            </Helmet>
            <NavbarContainer></NavbarContainer>
            {props.children}
            <div style={{flexGrow: 1}}></div>
            <FooterContainer></FooterContainer>
        </div>
    );
}

export default SiteContainer;