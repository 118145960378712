import React from 'react'

import { Helmet } from 'react-helmet'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import NavbarContainer from '../components/navbar-container'
import FooterContainer from '../components/footer-container'
import './home.css'


const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>ConTel - Convergencia de Telecomunicaciones</title>
        <meta property="og:title" content="ConTel - Convergencia de Telecomunicaciones" />
      </Helmet>
      <NavbarContainer rootClassName="navbar-container-root-class-name"></NavbarContainer>
      <div className="home-hero">
        <Carousel
          interval={3000}
          autoPlay={true}
          showThumbs={false}
          dynamicHeight={false}
          infiniteLoop={true}
          rootClassName="home-carousel-container"
        >
          <div>
              <img src="files/blue.jpg" />
          </div>
          <div>
              <img src="files/carousel-network.jpg"/>
          </div>
          <div>
              <img src="files/carousel-voip.jpg" />
          </div>
          <div>
              <img src="files/648-carousel.jpg" />
          </div>
        </Carousel>
      </div>
      <div className="home-section-separator"></div>
      <div className="mobile-links-section">
        Obtén más información sobre nuestros servicios:
        <a href="/nosotros">Nosotros</a>
        <a href="/servicios-cloud">Servicios cloud</a>
        <a href="/infraestructura">Infraestructura</a>
        <a href="/informacin-de-contacto">Contacta</a>
        <a href="/kit-digital">Kit Digital</a>
      </div>
      <div className="home-section-separator"></div>

      <h2 id="home-subtitle">Partner de soluciones en telecomunicaciones, informática y software para empresa </h2>

      <div className="home-timeline-card">
        <img src="/files/timeline1.png" alt="Nuestro proceso" className="timeline1-image2"></img>
      </div>


      <div className="contact-cta-btn-container">
        <a href="/informacin-de-contacto" className="contact-cta-btn">Solicítanos información</a>
      </div>

      <FooterContainer></FooterContainer>
    </div>
  )
}

export default Home
