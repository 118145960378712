import React from 'react';

import './info-container.css'

function InfoContainer(props) {
    return (
        <div className="info-site-container">
            <div className="info-content-box">
                {props.children}
            </div>
        </div>
    );
}

export default InfoContainer;